import React from "react";
import Form from "../../forms/onlyText";
import Footerlogo from "../../../assets/images/the-attributes-logo.svg";
import FacebookIcon from "../../icons/facebook";
import InstagramIcon from "../../icons/instagram";
import LinkedInIcon from "../../icons/linkedIn";
import TwitterIcon from "../../icons/twitter";
import { Link } from "gatsby";
import YouTube from "../../icons/youTube";
import { getImageSize } from "../../../sanity";
import Tiktok from "../../icons/Tiktok";
const Footer = ({ settings }) => {
  return (
    <>
      <footer className="bg-extra-dark-gray pt-12 px-6 lg:px-0">
        <div className="container mx-auto flex flex-col lg:flex-row justify-between px-0 lg:px-16">
          <div className="block lg:hidden">
            <img loading="lazy" src={Footerlogo} alt="the-attribute" />
          </div>
          <div className="w-full lg:w-26 flex flex-col justify-between">
            <div>
              <div className="w-full lg:w-11/12">
                <p className="text-2xl text-black font-extrabold leading-28  mb-4 pt-12 lg:pt-0">
                  Want to join The Attributes community?
                </p>
              </div>
              <p className="text-base text-black leading-24 mb-7 pr-2 lg:pr-0">
                Join 60k others learning how to recognize and develop their
                Attributes, build better teams, and become better leaders
              </p>
              <div className=" w-full lg:w-11/12 mb-8">
                <Form
                  text="Sign up"
                  name="newsletter"
                  event="joined_newsletter_footer"
                />
              </div>
            </div>
            <div>
              <p className="text-dark-gray text-base pb-3">
                * we will never sell your information
              </p>
            </div>
          </div>
          <div className="w-full lg:w-1/5 pt-8 pl-0 lg:pl-5 flex flex-col justify-between">
            <div className="flex justify-between">
              <div>
                <ul className="text-lg text-black">
                  {settings?.footerLinks
                    ?.filter((item) => item?.column === "first")
                    ?.map((link, linkIndex) => {
                      if (
                        link?.link?.indexOf("https:") !== -1 ||
                        link?.link?.indexOf("https:") !== -1 ||
                        link?.link?.indexOf("mailto") !== -1
                      ) {
                        let pr = {};
                        let checkMail = link?.link?.indexOf("mailto") === -1;
                        if (checkMail) {
                          pr = {
                            target: "_blank",
                            rel: "noopener noreferrer",
                          };
                        }
                        return (
                          <li className="leading-21 mb-3" key={linkIndex}>
                            <a
                              {...pr}
                              href={link?.link}
                              className="exlink hover:text-primary-red cursor-pointer"
                            >
                              {link?.title || ""}
                            </a>
                          </li>
                        );
                      } else {
                        return (
                          <li className="leading-21 mb-3" key={linkIndex}>
                            <Link
                              to={link?.link}
                              className="hover:text-primary-red cursor-pointer"
                            >
                              {link?.title || ""}
                            </Link>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>
              <div className="w-5 pr-8 block lg:hidden absolute right-3 z-40">
                <div className="mb-3 text-black">
                  <a
                    aria-label="Facebook link"
                    target="_blank"
                    href="https://www.facebook.com/The-Attributes-110380034019335/?view_public_for=110380034019335"
                    className="mb-3"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                </div>
                <div className="mb-3 text-black">
                  <a
                    aria-label="Instagram link"
                    target="_blank"
                    href="https://www.instagram.com/theattributes/"
                    className="mb-3"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                </div>
                <div className="mb-3 text-black">
                  <a
                    aria-label="LinkedIn link"
                    target="_blank"
                    href="https://www.linkedin.com/company/theattributes"
                    className="mb-3"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                </div>
                <div className="mb-3 text-black">
                  <a
                    aria-label="Twitter link"
                    target="_blank"
                    href="https://twitter.com/richdiviney"
                    className="mb-3"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon />
                  </a>
                </div>
                <div className="mb-3 text-black">
                  <a
                    aria-label="YouTube Link"
                    target="_blank"
                    href="https://www.youtube.com/theattributes"
                    rel="noopener noreferrer"
                  >
                    <YouTube />
                  </a>
                </div>
                <div className="mb-3 text-black">
                  <a
                    aria-label="Tiktok Link"
                    target="_blank"
                    href="https://www.tiktok.com/@rich_diviney"
                    rel="noopener noreferrer"
                  >
                    <Tiktok />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-18.6 pt-0 lg:pt-8 flex flex-col justify-between relative">
            <ul className="text-lg text-black">
              {settings?.footerLinks
                ?.filter((item) => item?.column === "second")
                ?.map((link, linkIndex) => {
                  if (
                    link?.link?.indexOf("https:") !== -1 ||
                    link?.link?.indexOf("https:") !== -1 ||
                    link?.link?.indexOf("mailto") !== -1
                  ) {
                    let pr = {};
                    let checkMail = link?.link?.indexOf("mailto") === -1;
                    if (checkMail) {
                      pr = {
                        target: "_blank",
                        rel: "noopener noreferrer",
                      };
                    }
                    return (
                      <li className="leading-21 mb-3" key={linkIndex}>
                        <a
                          {...pr}
                          href={link?.link}
                          className="exlink hover:text-primary-red cursor-pointer"
                        >
                          {link?.title || ""}
                        </a>
                      </li>
                    );
                  } else {
                    return (
                      <li className="leading-21 mb-3" key={linkIndex}>
                        <Link
                          to={link?.link}
                          className="hover:text-primary-red cursor-pointer"
                        >
                          {link?.title || ""}
                        </Link>
                      </li>
                    );
                  }
                })}
            </ul>
            {/* Terms of Use */}
            <div className="flex flex-col flex-col-reverse">
              <div className="flex justify-center">
                <div className="pb-3 mr-4 lg:mr-3 xl:mr-0 text-right">
                  <a
                    target="_blank"
                    href="https://hub.theattributes.com/legal?tab=terms"
                    className="text-dark-gray text-base cursor-pointer text-right"
                    rel="noopener noreferrer"
                  >
                    Terms of use
                  </a>
                </div>
                <div className="pb-3 lg:pl-0 pl-3 text-right lg:absolute bottom-0 -right-16">
                  <a
                    target="_blank"
                    href="https://hub.theattributes.com/legal?tab=privacy"
                    className="text-dark-gray text-base cursor-pointer text-left"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-5 pt-8 text-black hidden lg:block">
            <div className="mb-3 text-black">
              <a
                aria-label="Facebook link"
                target="_blank"
                href="https://www.facebook.com/The-Attributes-110380034019335/?view_public_for=110380034019335"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
            </div>
            <div className="mb-3 text-black">
              <a
                aria-label="Instagram link"
                target="_blank"
                href="https://www.instagram.com/theattributes/"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
            </div>
            <div className="mb-3 text-black">
              <a
                aria-label="LinkedIn link"
                target="_blank"
                href="https://www.linkedin.com/company/theattributes"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
            <div className="mb-3 text-black">
              <a
                aria-label="Twitter link"
                target="_blank"
                href="https://twitter.com/richdiviney"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>
            </div>
            <div className="mb-3 text-black">
              <a
                aria-label="YouTube Link"
                target="_blank"
                href="https://www.youtube.com/theattributes"
                rel="noopener noreferrer"
              >
                <YouTube />
              </a>
            </div>
            <div className="mb-3 text-black">
              <a
                aria-label="Tiktok Link"
                target="_blank"
                href="https://www.tiktok.com/@rich_diviney"
                rel="noopener noreferrer"
              >
                <Tiktok />
              </a>
            </div>
          </div>
          <div className="w-1/6 hidden lg:block">
            <div className="flex h-full items-end">
              <img
                width={
                  getImageSize(
                    "96614bf5bf86c2d38e6ae5409ae21fc524605b24-218x251.webp"
                  ).width
                }
                src="https://cdn.sanity.io/images/imclblh9/production/96614bf5bf86c2d38e6ae5409ae21fc524605b24-218x251.webp"
                alt="book"
                height="100px"
              />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
const DownloadForm = ({ text, name, event }) => {
  let formId = uuidv4();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();

    if (typeof window !== "undefined") {
      let myForm = document.getElementById(formId);
      let formData = new FormData(myForm);
      let body =
        new URLSearchParams(formData).toString() +
        `&form-name=Moving-Horizons-workbook`;
      axios
        .post("/", body, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          setSubmitted(true);
          if (typeof window !== "undefined") {
            if (event) {
              window.dataLayer = window.dataLayer || [];
              // eslint-disable-next-line
              dataLayer.push({
                event: "autoEvent",
                eventCategory: "Form Submission",
                eventAction: "Sign up Form",
                eventLabel: event,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {submitted ? (
        <p className="text-red-500 text-lg">Thank You!</p>
      ) : (
        <form
          action="/"
          id={formId}
          onSubmit={(e) => submitHandler(e)}
          className="flex flex-col"
          name={`Moving-Horizons-workbook`}
          data-netlify="true"
        >
          <div className="relative pb-4 flex">
            <label className="w-full" htmlFor="firstName">
              <input
                className="bg-transparent outline-none border-dark-beige border-b border-opacity-75 w-full pb-3 pr-4 placeholder-dark-beige"
                placeholder="First Name*"
                type="text"
                required={true}
                name="firstName"
              />
            </label>
            <label className="w-full" htmlFor="email">
              <input
                className=" bg-transparent outline-none border-dark-beige border-b border-opacity-75 w-full pb-3 placeholder-dark-beige"
                placeholder="Email Address*"
                type="email"
                required={true}
                name="email"
              />
            </label>
          </div>
          <div>
            <button
              disabled={loading}
              className="text-primary-red hover:text-black g-recaptcha"
              data-sitekey="6LeZLKgZAAAAALNR8unLBpLrO0OdgzItIcIS3arC"
            >
              {text}
            </button>
          </div>
        </form>
      )}
    </>
  );
};
export default DownloadForm;
